<script setup lang="ts">
import { ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { useCompanyTheme } from '../composables/useCompanyTheme';
import { registerClient } from '../services/api';
import type { FormData } from '../types';
import PersonalInfoStep from './form/PersonalInfoStep.vue';
import AddressStep from './form/AddressStep.vue';
import RegimeStep from './form/RegimeStep.vue';
import VehicleStep from './form/VehicleStep.vue';
import ConfirmationModal from './modals/ConfirmationModal.vue';
import DataTreatmentModal from './modals/DataTreatmentModal.vue';

const toast = useToast();
const { company } = useCompanyTheme();
const isLoading = ref(false);
const showConfirmModal = ref(false);
const showDataTreatmentModal = ref(false);

const formData = ref<FormData>({
  idType: '5',
  idNumber: '',
  name: '',
  lastName: '',
  email: '',
  city: '',
  address: '',
  regime: '1',
  plate: '',
  department: '',
  companyId: import.meta.env.VITE_COMPANY_ID || 'CHIA',
  verificationDigit: '',
  acceptTerms: false
});

const isNit = computed(() => formData.value.idType === '5');

const rules = computed(() => ({
  idType: { required },
  idNumber: { required },
  name: { required },
  lastName: { 
    required: helpers.withMessage(
      'Apellido es requerido', 
      (value: string) => {
        return isNit.value ? true : !!value;
      }
    )
  },
  email: { required, email },
  city: { required },
  address: { required },
  regime: { required },
  plate: { 
    required,
    format: helpers.withMessage(
      'Formato de placa inválido',
      (value: string) => {
        const carPlateFormat = /^[A-Z]{3}\d{3}$/;
        const motorbikePlateFormat = /^[A-Z]{3}\d{2}$/;
        const motorbikePlateFormatWithLetter = /^[A-Z]{3}\d{2}[A-Z]$/;
        const upperValue = value.toUpperCase();
        return (
          carPlateFormat.test(upperValue) || 
          motorbikePlateFormat.test(upperValue) ||
          motorbikePlateFormatWithLetter.test(upperValue)
        );
      }
    )
  },
  acceptTerms: { 
    required: helpers.withMessage('Debe aceptar los términos y condiciones', required) 
  }
}));

const v$ = useVuelidate(rules, formData);

const resetForm = () => {
  formData.value = {
    idType: '',
    idNumber: '',
    name: '',
    lastName: '',
    email: '',
    city: '',
    address: '',
    regime: '',
    plate: '',
    department: '',
    companyId: import.meta.env.VITE_COMPANY_ID || 'CHIA',
    verificationDigit: '',
    acceptTerms: false
  };
  v$.value.$reset();
};

const handleSubmit = async () => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    toast.error('Por favor complete todos los campos requeridos');
    return;
  }

  showConfirmModal.value = true;
};

const submitForm = async () => {
  try {
    isLoading.value = true;
    showConfirmModal.value = false;
    
    const submitData = {
      ...formData.value,
      idNumber: formData.value.idType === '5' ? String(formData.value.idNumber) + String(formData.value.verificationDigit) : formData.value.idNumber,
      plate: formData.value.plate.toUpperCase(),
    };

    await registerClient(submitData);
    toast.success('Registro exitoso');
    resetForm();
  } catch (error) {
    console.error('Error submitting form:', error);
    toast.error('Error al registrar. Por favor intente nuevamente');
  } finally {
    isLoading.value = false;
  }
};

const openDataTreatmentModal = () => {
  showDataTreatmentModal.value = true;
};

const acceptDataTreatment = () => {
  formData.value.acceptTerms = true;
  showDataTreatmentModal.value = false;
};
</script>

<template>
  <div class="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
      <!-- Header -->
      <div class="text-center mb-12">
        <img :src="company.logoUrl" alt="Logo" class="mx-auto h-32 mb-6">
        <h1 class="text-3xl font-bold text-gray-900 mb-2">Facturación Electrónica</h1>
        <p class="text-gray-600">Complete el formulario para recibir su factura electrónica</p>
      </div>

      <!-- Form -->
      <form @submit.prevent="handleSubmit" class="bg-white shadow-lg rounded-lg p-8">
        <div class="space-y-8">
          <PersonalInfoStep :form-data="formData" :v$="v$" />
          <AddressStep :form-data="formData" :v$="v$" />
          <RegimeStep :form-data="formData" :v$="v$" />
          <VehicleStep :form-data="formData" :v$="v$" />
          
          <!-- Terms and Submit -->
          <div class="space-y-4 pt-4 border-t">
            <div class="flex items-center">
              <input 
                type="checkbox" 
                class="checkbox checkbox-primary" 
                v-model="formData.acceptTerms"
                :class="{ 'checkbox-error': v$.acceptTerms.$error }">
              <label class="ml-2 text-sm text-gray-600">
                Acepto los 
                <button 
                  type="button"
                  class="text-primary hover:underline"
                  @click="openDataTreatmentModal">
                  términos y condiciones
                </button>
                <span v-if="v$.acceptTerms.$error" class="text-error">*</span>
              </label>
            </div>

            <button 
              type="submit"
              class="btn btn-primary w-full"
              :disabled="isLoading">
              <span v-if="isLoading" class="loading loading-spinner"></span>
              {{ isLoading ? 'Enviando...' : 'Enviar Información' }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <footer class="text-center mt-8 text-sm text-gray-500">
      <p>Versión 1.0.12</p>
    </footer>

    <!-- Modals -->
    <ConfirmationModal
      :is-open="showConfirmModal"
      :form-data="formData"
      @close="showConfirmModal = false"
      @confirm="submitForm"
    />

    <DataTreatmentModal
      :is-open="showDataTreatmentModal"
      @close="showDataTreatmentModal = false"
      @accept="acceptDataTreatment"
    />
  </div>
</template>