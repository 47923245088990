import { defineStore } from 'pinia';
import type { Company } from '../types';

export const useCompanyStore = defineStore('company', {
  state: () => ({
    company: {
      id: import.meta.env.VITE_COMPANY_ID || 'CHIA',
      primaryColor: '#e62f29',
      logoUrl: '/logo_chia.png'
    } as Company
  }),
  
  actions: {
    async fetchCompanyConfig() {
      try {
        // Here you would typically fetch the company configuration
        // For now we'll use default values
        this.setTheme();
      } catch (error) {
        console.error('Error fetching company config:', error);
      }
    },

    setTheme() {
      document.documentElement.style.setProperty('--primary-color', this.company.primaryColor);
    }
  }
});