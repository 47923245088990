<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  isOpen: boolean;
}>();

defineEmits<{
  (e: 'close'): void;
  (e: 'accept'): void;
}>();

const activeTab = ref('general');
</script>

<template>
  <dialog :class="{ 'modal': true, 'modal-open': isOpen }">
    <div class="modal-box max-w-4xl bg-white">
      <!-- Header -->
      <div class="flex items-center justify-between border-b pb-4 mb-6">
        <div>
          <h3 class="text-2xl font-bold text-gray-900">Política de Tratamiento de Datos</h3>
          <p class="text-sm text-gray-500 mt-1">Lea cuidadosamente nuestras políticas de privacidad</p>
        </div>
        <button 
          class="btn btn-sm btn-circle btn-ghost hover:bg-gray-100"
          @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Navigation Tabs -->
      <div class="flex space-x-1 p-1 bg-gray-50 rounded-lg mb-6">
        <button 
          v-for="tab in ['general', 'Derechos como Titular']"
          :key="tab"
          class="flex-1 px-4 py-2 text-sm font-medium rounded-md transition-all duration-200"
          :class="activeTab === tab ? 'bg-white text-gray-900 shadow-sm' : 'text-gray-500 hover:text-gray-700'"
          @click="activeTab = tab">
          {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
        </button>
      </div>

      <div class="overflow-y-auto max-h-[60vh] pr-4 space-y-6">
        <!-- General Tab -->
        <div v-show="activeTab === 'general'" class="space-y-6">
          <div class="bg-white rounded-lg border">
            <div class="p-6">
              <h4 class="text-lg font-semibold text-gray-900 mb-4">Recolección y Uso de Datos</h4>
              <p class="text-gray-600 mb-6 leading-relaxed">
                En cumplimiento con la Ley 1581 de 2012, recolectamos y procesamos sus datos personales de manera segura y responsable.
              </p>
              <div class="space-y-6">
                <div class="flex items-start gap-4">
                  <div class="bg-blue-50 p-3 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h5 class="font-medium text-gray-900">Facturación Electrónica</h5>
                    <p class="text-sm text-gray-600 mt-1 leading-relaxed">Generación y envío de facturas electrónicas según normativa vigente</p>
                  </div>
                </div>
                <div class="flex items-start gap-4">
                  <div class="bg-green-50 p-3 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h5 class="font-medium text-gray-900">Gestión de Servicios</h5>
                    <p class="text-sm text-gray-600 mt-1 leading-relaxed">Administración eficiente de servicios de parqueadero</p>
                  </div>
                </div>
                <div class="flex items-start gap-4">
                  <div class="bg-purple-50 p-3 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div>
                    <h5 class="font-medium text-gray-900">Comunicaciones</h5>
                    <p class="text-sm text-gray-600 mt-1 leading-relaxed">Envío de información relevante sobre servicios y actualizaciones</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Rights Tab -->
        <div v-show="activeTab === 'Derechos como Titular'" class="space-y-6">
          <div class="bg-white rounded-lg border">
            <div class="p-6">
              <h4 class="text-lg font-semibold text-gray-900 mb-6">Sus Derechos como Titular</h4>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="bg-gray-50 rounded-lg p-5">
                  <h5 class="font-medium text-gray-900 mb-3">Acceso y Actualización</h5>
                  <ul class="space-y-2">
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Conocer sus datos personales</span>
                    </li>
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Actualizar información inexacta</span>
                    </li>
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Rectificar datos incorrectos</span>
                    </li>
                  </ul>
                </div>
                <div class="bg-gray-50 rounded-lg p-5">
                  <h5 class="font-medium text-gray-900 mb-3">Control y Autorización</h5>
                  <ul class="space-y-2">
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Solicitar prueba de autorización</span>
                    </li>
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Revocar el consentimiento</span>
                    </li>
                    <li class="flex items-center gap-2 text-gray-600">
                      <svg class="h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <span class="text-sm">Solicitar supresión de datos</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="modal-action mt-6 border-t pt-4">
        <button 
          class="btn btn-ghost hover:bg-gray-100"
          @click="$emit('close')">
          Cancelar
        </button>
        <button 
          class="btn bg-primary text-white hover:bg-primary/90"
          @click="$emit('accept')">
          Acepto los Términos
        </button>
      </div>
    </div>
  </dialog>
</template>

<style scoped>
/* Custom scrollbar */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.gray.300') theme('colors.gray.100');
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: theme('colors.gray.100');
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme('colors.gray.300');
  border-radius: 3px;
}
</style>