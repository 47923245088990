<script setup lang="ts">
import { ref, computed } from 'vue';
import FormField from '../FormField.vue';
import { cities } from '../../constants/cities';
import type { FormData, City } from '../../types';
import type { Validation } from '@vuelidate/core';

const props = defineProps<{
  formData: FormData;
  v$: Validation;
}>();

const searchQuery = ref('');
const showSuggestions = ref(false);

const filteredCities = computed(() => {
  if (!searchQuery.value) return [];
  
  const query = searchQuery.value.toLowerCase().trim();
  return cities
    .filter(city => 
      city.city.toLowerCase().includes(query) || 
      city.department.toLowerCase().includes(query)
    )
    .slice(0, 5); // Limit to 5 suggestions
});

const selectCity = (city: City) => {
  searchQuery.value = `${city.city} - ${city.department}`;
  props.formData.city = city.cityDANE;
  props.formData.department = city.departmentDANE;
  showSuggestions.value = false;
};

const handleInputFocus = () => {
  showSuggestions.value = true;
};

const handleInputBlur = () => {
  // Delay hiding suggestions to allow click events on suggestions
  setTimeout(() => {
    showSuggestions.value = false;
  }, 200);
};
</script>

<template>
  <div class="space-y-6">
    <h2 class="text-xl font-semibold text-gray-900 border-b pb-2">
      Paso 2: Información de Ubicación
    </h2>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <FormField 
        label="Ciudad" 
        required
        :error="v$.city.$error ? 'Seleccione una ciudad' : ''">
        <div class="relative">
          <input 
            v-model="searchQuery"
            type="text"
            class="input input-bordered w-full"
            placeholder="Buscar ciudad..."
            @focus="handleInputFocus"
            @blur="handleInputBlur">
          
          <!-- Suggestions Dropdown -->
          <div 
            v-if="showSuggestions && filteredCities.length > 0"
            class="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200 max-h-60 overflow-auto">
            <ul class="py-1">
              <li 
                v-for="city in filteredCities" 
                :key="city.cityDANE"
                class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                @mousedown="selectCity(city)">
                {{ city.city }} - {{ city.department }}
              </li>
            </ul>
          </div>
        </div>
      </FormField>

      <FormField 
        label="Dirección" 
        required
        :error="v$.address.$error ? 'Ingrese una dirección válida' : ''">
        <input 
          v-model="formData.address"
          type="text"
          class="input input-bordered w-full"
          placeholder="Ej: Calle 123 # 45-67">
      </FormField>
    </div>
  </div>
</template>

<style scoped>
.suggestions-enter-active,
.suggestions-leave-active {
  transition: opacity 0.2s ease;
}

.suggestions-enter-from,
.suggestions-leave-to {
  opacity: 0;
}
</style>