import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import './style.css'
import App from './App.vue'
import { useCompanyStore } from './stores/companyStore'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(Toast)

// Initialize company theme before mounting
const companyStore = useCompanyStore(pinia)

// Envolvemos la inicialización en una función async
const init = async () => {
  try {
    await companyStore.fetchCompanyConfig()
    app.mount('#app')
  } catch (error) {
    console.error('Error initializing app:', error)
  }
}

init()