import axios from 'axios';

const baseURL = import.meta.env.DEV 
  ? 'https://park-factura-prod.uc.r.appspot.com/api/v1'
  : 'https://park-factura-prod.uc.r.appspot.com/api/v1';

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registerClient = async (data: any) => {
  return api.post('/client', data);
};