import { storeToRefs } from 'pinia';
import { useCompanyStore } from '../stores/companyStore';

export function useCompanyTheme() {
  const store = useCompanyStore();
  const { company } = storeToRefs(store);

  return {
    company
  };
}